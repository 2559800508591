<template>
  <div>
    <v-card flat tile>
      <v-card-text>
        <v-alert v-if="errors.length" type="error" :value="true">
          <ul>
            <li v-for="error in errors" :key="error">{{ error }}</li>
          </ul>
        </v-alert>
    <v-form ref="form" v-model="valid">
      <v-row
        no-gutters
      >
        <v-col
          cols="12"
        >
          <v-text-field
            :label="`${$t('name')}*`"
            v-model="model.name"
            :rules="nameRules"
            :disabled="!allowEdit"
          ></v-text-field>
          <v-textarea
            label="Description"
            auto-grow
            v-model="model.description"
            :disabled="!allowEdit"
          ></v-textarea>
        </v-col>

      </v-row>
    </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        plain
        color="default"
        @click="onCancel"
      >
        {{ $t('cancel') }}
      </v-btn>
      <v-btn
        tile
        large
        color="primary"
        @click="onSave"
        :disabled="!valid"
      >
        {{ $t('save') }}
      </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import _ from 'lodash'

  export default {
    props: {
      allowEdit: {
        type: Boolean,
        default: true
      },
      value: {
        type: Object,
        required: true
      }
    },
    data(){
      return {
        errors: [],
        model: {},
        valid: false,
        nameRules: [
          v => !!v || this.$t('required'),
          v => (v && v.length <= 255) || this.$t('maxLength', { length: 255 })
        ],
      }
    },
    computed:{
      isNew() {
        return this.model.id ? true : false
      }
    },
    mounted() {
      this.model = _.cloneDeep(this.value)
    },
    watch: {
      value(newVal) {
        if (newVal) {
          this.model = _.cloneDeep(newVal)
        } else {
          this.model = {}
        }
      }
    },
    methods: {
      onCancel() {
        this.$emit('cancel')
      },
      onSave() {
          this.$refs.form.validate()
          if (!this.valid) {
            return
          }

          this.$emit('save', this.model)

      }
    }
  }
</script>
